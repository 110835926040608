<template>
    <v-chart :options="option"/>
</template>

<script>
    export default {
        props: {
            dataset: {
                type: Object,
                required: true,
            },
            dimensions: {
                type: Array,
                default: undefined,
            },
        },

        computed: {
            option() {
                if (this.dataset === undefined) {
                    return undefined;
                }

                return {
                    legend: {},
                    tooltip: {},
                    dataset: {
                        dimensions: this.dimensions || this.dataset.columns,
                        source: this.dataset.rows,
                    },
                    xAxis: { name: 'Number of customer' },
                    yAxis: { type: 'category' },
                    // Declare several bar series, each will be mapped
                    // to a column of dataset.source by default.
                    visualMap: {
                        orient: 'horizontal',
                        left: 'center',
                        min: 10,
                        max: 5100,
                        text: ['High Score', 'Low Score'],
                        // Map the score column to color
                        dimension: 0,
                        inRange: {
                            color: ['#65B581', '#FFCE34', '#FD665F'],
                        },
                    },
                    series: [
                        {
                            type: 'bar',
                            encode: {
                                // Map the "amount" column to X axis.
                                x: 'CUSTOMER_ID (count)',
                                // Map the "product" column to Y axis
                                y: 'TAG',
                            },
                        },
                    ],
                };
            },
        },
    };
</script>

<style scoped>
    .echarts {
        height: 500px;
        width: 100%;
    }
</style>
