<template>
    <request :request="listDataset" @on-success="onRequestSuccess">
        <v-dialog
            v-model="dialog"
            width="800"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="secondary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    icon
                >
                    <v-icon>$data</v-icon>
                </v-btn>
            </template>

            <v-card>
                <v-card-title class="headline grey lighten-2">
                    Datasets
                </v-card-title>

                <v-card-text>
                    <v-list two-line>
                        <v-list-item-group
                            v-model="selected"
                            active-class="black--text"
                        >
                            <template v-for="(item, index) in dataset.data">
                                <v-list-item :key="item.id">
                                    <template v-slot:default="{  }">
                                        <v-list-item-content>
                                            <v-list-item-title v-text="item.name" />
                                            <small>
                                                {{ item.metadata.created_at }}
                                            </small>

                                            <small>
                                                {{ item.metadata.created_by.first_name }}
                                                {{ item.metadata.created_by.last_name }}
                                            </small>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <v-list-item-action-text>
                                                <v-chip small dark color="green">
                                                    {{ item.metadata.datatype.toUpperCase() }}
                                                </v-chip>
                                                <v-chip color="blue" dark small>
                                                    {{ item.client.toUpperCase() }}
                                                </v-chip>
                                            </v-list-item-action-text>
                                        </v-list-item-action>
                                    </template>
                                </v-list-item>
                                <v-divider
                                    v-if="index < dataset.data.length - 1"
                                    :key="index"
                                />
                            </template>
                        </v-list-item-group>
                    </v-list>
                </v-card-text>

                <v-divider />

                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="primary"
                        text
                        @click="selectDataset"
                    >
                        SELECT
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </request>
</template>

<script>
    import constants from '@/constants';
    import webapi from '@/core/webapi';

    const URI = `${constants.API_BASE}/report/datastore?datatype=dataframe`;

    export default {
        name: 'DatasetSelector',

        data: () => ({
            dialog: false,
            selected: undefined,
            dataset: [],
        }),

        computed: {
            selectedDataset() {
                if (this.selected !== undefined) {
                    return this.dataset[this.selected];
                }
                return undefined;
            },
        },

        watch: {
            value: {
                immediate: true,
                handler(newValue) {
                    this.selectedCountry = newValue;
                },
            },
        },
        methods: {
            onRequestSuccess(data) {
                this.dataset = data;
            },
            async listDataset() {
                const uri = URI;
                return webapi.get({ uri });
            },
            selectDataset() {
                const selectedDataset = this.dataset.data[this.selected];
                this.$emit('input', selectedDataset);
                console.log('EMIT DATASET', selectedDataset, this.selected);
                this.selected = undefined;
                this.dialog = false;
            },
        },
    };
</script>
