<template>
    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="orange"
                v-bind="attrs"
                v-on="on"
                icon
                x-small
                dark
            >
                <v-icon>fas fa-exclamation-circle</v-icon>
            </v-btn>
        </template>

        <v-card>
            <v-card-text>
                <v-alert
                    border="bottom"
                    outlined
                    type="warning"
                    v-for="(message, i) in alerts" :key="i">
                    {{ message }}
                </v-alert>
            </v-card-text>
        </v-card>
    </v-menu>
</template>

<script>
    export default {
        name: 'Alerts',

        props: {
            alerts: {
                type: Array,
                default: () => [],
            },
        },

        data: () => ({
            menu: false,
        }),
    };
</script>
