<template>
    <v-row>
        <v-col cols=12>
            <h2>RFM Results</h2>
            <alerts v-if="result.messages" :alerts="result.messages" />
        </v-col>

        <v-col cols=12>
            <v-tabs
                v-model="tab"
                background-color="transparent"
            >
                <v-tab key="summary">
                    Summary
                </v-tab>
                <v-tab key="details">
                    Details
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
                <v-tab-item key="summary" background-color="transparent">
                    <chart :dataset="result.rfm_chart" />
                    <custom-table :dataset="result.rfm" title="Summary" />
                </v-tab-item>
                <v-tab-item key="details">
                    <chart :dataset="result.rfm" />
                    <data-table :dataset="result.dataset"  />
                </v-tab-item>
            </v-tabs-items>
        </v-col>
    </v-row>
</template>

<script>
    import CustomTable from './Table.vue';
    import DataTable from './DataTable.vue';
    import Chart from './Chart.vue';
    import Alerts from './Alerts.vue';

    export default {
        name: 'RFMResultViewer',

        components: {
            Alerts,
            CustomTable,
            Chart,
            DataTable,
        },

        data: () => ({
            tab: 'summary',
        }),

        props: {
            result: {
                type: Object,
                requried: true,
            },
        },
    };
</script>

<style scoped>
    .theme--light.v-tabs-items {
        background: transparent !important;
    }
</style>
