<template>
    <v-card>
        <v-card-title v-if="title">{{ title }}</v-card-title>
        <v-simple-table class="text-left">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th v-for="(col, i) in columns" :key="i">
                            {{ col }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(row, r) in rows"
                        :key="r"
                    >
                        <td v-for="(col, c) in columns" :key="c">{{ row[col] }}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </v-card>
</template>

<script>
    import dateutils from '@/utils/dateutils';

    export default {
        name: 'DatastoreListTable',

        props: {
            title: {
                type: String,
                default: undefined,
            },
            dataset: {
                type: Object,
                required: true,
            },
        },

        computed: {
            rows() {
                return this.dataset.rows;
            },
            columns() {
                return this.dataset.columns;
            },
        },

        filters: {
            datetime: dateutils.datetime,
        },
    };
</script>
