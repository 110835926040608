<template>
    <v-row>
        <v-col cols=12>
            <h2>RFM Model</h2>
            <p>Segment your customer with RFM MODEL</p>
        </v-col>
        <v-col>
            <v-tabs
                v-model="tab"
                background-color="transparent"
            >
                <v-tab key="configurations">
                    Configuration
                </v-tab>
                <v-tab key="results" :disabled="!result">
                    Results
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
                <v-tab-item key="configurations">
                    <configuration-alt @on-result="onResult" />
                </v-tab-item>
                <v-tab-item key="results">
                    <viewer :result="result" />
                </v-tab-item>
            </v-tabs-items>
        </v-col>
    </v-row>
</template>

<script>
    import ConfigurationAlt from './RfmModel/ConfigurationAlt.vue';
    import Viewer from './RfmModel/Viewer.vue';

    export default {
        name: 'ManageRfmModel',

        components: {
            ConfigurationAlt,
            Viewer,
        },

        data: () => ({
            result: undefined,
            tab: 0,
        }),

        computed: {

        },

        watch: {
        },

        methods: {
            onResult(result) {
                this.result = result;
                this.tab = 1;
            },
        },
    };
</script>

<style scoped>
    .theme--light.v-tabs-items {
        background: transparent !important;
    }
</style>
